import React, { useState, useEffect, useCallback } from 'react'
import { baseAPI } from '../config'
import * as Icon from 'react-feather'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import Loading from './common/Loading'
import { showSidebar, hideSidebar } from '../redux/actions/settings'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

const CardForm = ({
  expedient,
  createExpedient,
  setBasePrice,
  isUrgentHearing,
  isTelematic,
  isProcura,
  promotion,
  reload,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  const { activeUser } = useSelector((state) => state.auth)
  const createdExpedient = useSelector((state) => state.expedient.created)
  const [actualCards, setActualCards] = useState([])
  const [stripeConfirmId, setStripeConfirmId] = useState('')
  const [showSaveCards, setShowSaveCards] = useState(true)
  const [loading, setLoading] = useState(false)

  const manageSidebarToShow = () => {
    if (window.innerWidth < 992) {
      dispatch(hideSidebar())
    } else {
      dispatch(showSidebar())
    }
  }

  const getToken = useCallback(
    async ({ promotionName }) => {
      return await fetch(`${baseAPI}/checkout-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          user_id: activeUser._id,
          promotionName,
          paymentPlatform: 'card',
          isUrgentHearing,
          isTelematic,
          isProcura,
        }),
      })
        .then(async (res) => {
          if (res.status === 200) {
            return await res.json()
          }
        })
        .catch((err) => {
          return false
        })
    },
    [activeUser, isProcura, isTelematic, isUrgentHearing]
  )

  const updatePaymentConditions = useCallback(() => {
    getToken({ promotionName: promotion ? promotion.name : promotion })
      .then((res) => {
        setLoading(true)
        setStripeConfirmId(res.token.client_secret)
        setActualCards(res.cards)
        if (setBasePrice) {
          setBasePrice(res.basePrice)
        }
      })
      .catch((err) => {
        return toast.error('Algo salió mal', { theme: 'colored' })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [getToken, promotion, setBasePrice])

  useEffect(() => {
    const fetchData = () => {
      setLoading(true)
      updatePaymentConditions()
    }
    reload && fetchData()
  }, [updatePaymentConditions, reload])

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    setLoading(true)

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      toast.error('No se encontró el elemento de la tarjeta', {
        theme: 'colored',
      })
      setLoading(false)
      return
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      stripeConfirmId,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            email: activeUser.email,
            name: activeUser.name,
          },
        },
        setup_future_usage: 'off_session',
      }
    )

    if (error) {
      setLoading(false)
      return toast.error(error.message, { theme: 'colored' })
    }

    expedient.payment_data = paymentIntent
    expedient.payment_status = 'paid'
    expedient.promotion_name = promotion ? promotion.name : promotion
    expedient.is_urgent_hearing = isUrgentHearing

    await createExpedient(expedient)
    manageSidebarToShow()
    if (createdExpedient) {
      setLoading(false)
      navigate('/panel/expedients')
    }
  }

  const paymentSaveCard = async (paymentMethodId) => {
    setLoading(true)
    const url = `${baseAPI}/charge-save-card`

    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: activeUser._id,
        payment_method_id: paymentMethodId,
        promotionName: promotion.name,
        isUrgentHearing,
        expedient,
        isProcura,
        isTelematic,
      }),
    })
      .then(async (res) => {
        if (res.status === 200) return res.json()
      })
      .then(async (res) => {
        if (res.error) {
          toast.error(res.error.message, { theme: 'colored' })
        }
        expedient.payment_data = res
        expedient.payment_status = 'paid'
        expedient.promotion_name = promotion.name
        expedient.is_urgent_hearing = isUrgentHearing
        await createExpedient(expedient)
        manageSidebarToShow()
        setLoading(false)
      })
      .catch((_err) => {
        setLoading(false)
        return toast.error('Algo salió mal', { theme: 'colored' })
      })
  }

  return (
    <div>
      <Loading loading={loading} />

      <div style={{ display: `${!actualCards.length ? 'none' : ''}` }}>
        <h4 className="blue2 mb-0">Pague con una de sus tarjetas</h4>
        <ul className="px-0" style={{ maxWidth: '320px' }}>
          {actualCards.map((card) => (
            <li
              key={card.pmId}
              className="my-3 d-flex flex-column"
              style={{ listStyle: 'none' }}
            >
              <Button
                className="btn btn-success d-flex justify-content-between"
                style={{ lineHeight: '1.8em' }}
                onClick={() => paymentSaveCard(card.pmId)}
                disabled={loading}
              >
                <Icon.CreditCard />
                <div>Pagar con: {card.brand} </div>
                {` termina en ${card.last4}`}
              </Button>
            </li>
          ))}
        </ul>
        <div className="d-flex justify-content-between">
          <Button
            outline
            style={{ display: `${showSaveCards ? '' : 'none'}` }}
            onClick={() => setShowSaveCards(false)}
          >
            Añadir tarjeta
          </Button>
        </div>
      </div>

      <form
        id="stripeForm"
        style={{
          display: `${showSaveCards && !!actualCards.length ? 'none' : ''}`,
        }}
        onSubmit={handleSubmit}
      >
        <h4 className="blue2 mb-0">Tarjeta</h4>
        <small className="mb-3">
          (Número de tarjeta, fecha de caducidad, CVC, código postal)
        </small>
        <div className="mt-3">
          <CardElement className="MyCardElement" style={style} />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-md-center m-3">
          <button
            id="payStripe"
            className="text-center btn btn-success m-1 sm-m-t-1 btn-lg"
          >
            Pagar de forma segura
          </button>
        </div>
      </form>
    </div>
  )
}

export default CardForm

const style = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
}
