// #region SETTINGS
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";

// #region Auth
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const PRELOGIN_REQUEST = 'LOGIN_REQUEST'
export const PRELOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const PRELOGIN_ERROR = 'LOGIN_ERROR'

export const LOGIN_FORM_REQUEST = 'LOGIN_FORM_REQUEST'
export const LOGIN_FORM_SUCCESS = 'LOGIN_FORM_SUCCESS'
export const LOGIN_FORM_ERROR = 'LOGIN_FORM_ERROR'

export const SESSION_REQUEST = 'SESSION_REQUEST'
export const SESSION_SUCCESS = 'SESSION_SUCCESS'
export const SESSION_ERROR = 'SESSION_ERROR'

export const CHANGE_EMAIL_REQ_REQUEST = 'CHANGE_EMAIL_REQ_REQUEST'
export const CHANGE_EMAIL_REQ_SUCCESS = 'CHANGE_EMAIL_REQ_SUCCESS'
export const CHANGE_EMAIL_REQ_ERROR = 'CHANGE_EMAIL_REQ_ERROR'

export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST'
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS'
export const CHANGE_EMAIL_ERROR = 'CHANGE_EMAIL_ERROR'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

// #region USERS
export const LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST'
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS'
export const LOAD_USERS_ERROR = 'LOAD_USERS_ERROR'

export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST'
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS'
export const CREATE_CLIENT_ERROR = 'CREATE_CLIENT_ERROR'

export const CREATE_CLIENT_FORM_REQUEST = 'CREATE_CLIENT_REQUEST'
export const CREATE_CLIENT_FORM_SUCCESS = 'CREATE_CLIENT_SUCCESS'
export const CREATE_CLIENT_FORM_ERROR = 'CREATE_CLIENT_ERROR'

export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'

export const LOAD_SUBTITUTES_REQUEST = 'LOAD_SUBTITUTES_REQUEST'
export const LOAD_SUBTITUTES_SUCCESS = 'LOAD_SUBTITUTES_SUCCESS'
export const LOAD_SUBTITUTES_ERROR = 'LOAD_SUBTITUTES_ERROR'

export const ADD_SUBSTITUTE_EXCLUDED_REQUEST = 'ADD_SUBSTITUTE_EXCLUDED_REQUEST'
export const ADD_SUBSTITUTE_EXCLUDED_SUCCESS = 'ADD_SUBSTITUTE_EXCLUDED_SUCCESS'
export const ADD_SUBSTITUTE_EXCLUDED_ERROR = 'ADD_SUBSTITUTE_EXCLUDED_ERROR'

export const REMOVE_SUBSTITUTE_EXCLUDED_REQUEST = 'REMOVE_SUBSTITUTE_EXCLUDED_REQUEST'
export const REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS = 'REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS'
export const REMOVE_SUBSTITUTE_EXCLUDED_ERROR = 'REMOVE_SUBSTITUTE_EXCLUDED_ERROR'

export const ADD_FAVORITE_SUBSTITUTE_REQUEST = 'ADD_FAVORITE_SUBSTITUTE_REQUEST'
export const ADD_FAVORITE_SUBSTITUTE_SUCCESS = 'ADD_FAVORITE_SUBSTITUTE_SUCCESS'
export const ADD_FAVORITE_SUBSTITUTE_ERROR = 'ADD_FAVORITE_SUBSTITUTE_ERROR'

export const REMOVE_FAVORITE_SUBSTITUTE_REQUEST = 'REMOVE_FAVORITE_SUBSTITUTE_REQUEST'
export const REMOVE_FAVORITE_SUBSTITUTE_SUCCESS = 'REMOVE_FAVORITE_SUBSTITUTE_SUCCESS'
export const REMOVE_FAVORITE_SUBSTITUTE_ERROR = 'REMOVE_FAVORITE_SUBSTITUTE_ERROR'

export const CHANGE_PARTNER_TO_COMPANY_REQUEST = 'CHANGE_PARTNER_TO_COMPANY_REQUEST'
export const CHANGE_PARTNER_TO_COMPANY_SUCCESS = 'CHANGE_PARTNER_TO_COMPANY_SUCCESS'
export const CHANGE_PARTNER_TO_COMPANY_ERROR = 'CHANGE_PARTNER_TO_COMPANY_ERROR'

export const ADD_AUTH_POOL_REQUEST = 'ADD_AUTH_POOL_REQUEST'
export const ADD_AUTH_POOL_SUCCESS = 'ADD_AUTH_POOL_SUCCESS'
export const ADD_AUTH_POOL_ERROR = 'ADD_AUTH_POOL_ERROR'

export const EDIT_AUTH_POOL_REQUEST = 'EDIT_AUTH_POOL_REQUEST'
export const EDIT_AUTH_POOL_SUCCESS = 'EDIT_AUTH_POOL_SUCCESS'
export const EDIT_AUTH_POOL_ERROR = 'EDIT_AUTH_POOL_ERROR'

export const URGENT_ISSUE_REMINDER_EXPEDIENT_REQUEST = 'URGENT_ISSUE_REMINDER_EXPEDIENT_REQUEST'
export const URGENT_ISSUE_REMINDER_EXPEDIENT_SUCCESS = 'URGENT_ISSUE_REMINDER_EXPEDIENT_SUCCESS'
export const URGENT_ISSUE_REMINDER_EXPEDIENT_ERROR = 'URGENT_ISSUE_REMINDER_EXPEDIENT_ERROR'

export const URGENT_ISSUE_REMINDER_PROCURA_REQUEST = 'URGENT_ISSUE_REMINDER_PROCURA_REQUEST'
export const URGENT_ISSUE_REMINDER_PROCURA_SUCCESS = 'URGENT_ISSUE_REMINDER_PROCURA_SUCCESS'
export const URGENT_ISSUE_REMINDER_PROCURA_ERROR = 'URGENT_ISSUE_REMINDER_PROCURA_ERROR'

export const UPDATE_USER_FEEDBACK_SCORE_REQUEST = "UPDATE_USER_FEEDBACK_SCORE_REQUEST"
export const UPDATE_USER_FEEDBACK_SCORE_SUCCESS = "UPDATE_USER_FEEDBACK_SCORE_SUCCESS"
export const UPDATE_USER_FEEDBACK_SCORE_ERROR = "UPDATE_USER_FEEDBACK_SCORE_ERROR"

// #region USERS - LINKED PARTNERS
export const SET_LINKED_PARTNERS_REQUEST = 'SET_LINKED_PARTNERS_REQUEST'
export const SET_LINKED_PARTNERS_SUCCESS = 'SET_LINKED_PARTNERS_SUCCESS'
export const SET_LINKED_PARTNERS_ERROR = 'SET_LINKED_PARTNERS_ERROR'

export const DELETE_LINKED_PARTNER_REQUEST = 'DELETE_LINKED_PARTNER_REQUEST'
export const DELETE_LINKED_PARTNER_SUCCESS = 'DELETE_LINKED_PARTNER_SUCCESS'
export const DELETE_LINKED_PARTNER_ERROR = 'DELETE_LINKED_PARTNER_ERROR'

// #region USERS - CUSTOM FIELDS
export const ADD_CUSTOM_FIELD_REQUEST = 'ADD_CUSTOM_FIELD_REQUEST'
export const ADD_CUSTOM_FIELD_SUCCESS = 'ADD_CUSTOM_FIELD_SUCCESS'
export const ADD_CUSTOM_FIELD_ERROR = 'ADD_CUSTOM_FIELD_ERROR'

export const UPDATE_CUSTOM_FIELD_REQUEST = 'UPDATE_CUSTOM_FIELD_REQUEST'
export const UPDATE_CUSTOM_FIELD_SUCCESS = 'UPDATE_CUSTOM_FIELD_SUCCESS'
export const UPDATE_CUSTOM_FIELD_ERROR = 'UPDATE_CUSTOM_FIELD_ERROR'

// #region EXPEDIENT
export const CREATE_EXPEDIENT_REQUEST = 'CREATE_EXPEDIENT_REQUEST'
export const CREATE_EXPEDIENT_SUCCESS = 'CREATE_EXPEDIENT_SUCCESS'
export const CREATE_EXPEDIENT_ERROR = 'CREATE_EXPEDIENT_ERROR'

export const CREATE_SELF_EXPEDIENT_REQUEST = 'CREATE_SELF_EXPEDIENT_REQUEST'
export const CREATE_SELF_EXPEDIENT_SUCCESS = 'CREATE_SELF_EXPEDIENT_SUCCESS'
export const CREATE_SELF_EXPEDIENT_ERROR = 'CREATE_SELF_EXPEDIENT_ERROR'

export const LOAD_EXPEDIENT_REQUEST = 'LOAD_EXPEDIENT_REQUEST'
export const LOAD_EXPEDIENT_SUCCESS = 'LOAD_EXPEDIENT_SUCCESS'
export const LOAD_EXPEDIENT_ERROR = 'LOAD_EXPEDIENT_ERROR'

export const LOAD_EXPEDIENTS_REQUEST = 'LOAD_EXPEDIENTS_REQUEST'
export const LOAD_EXPEDIENTS_SUCCESS = 'LOAD_EXPEDIENTS_SUCCESS'
export const LOAD_EXPEDIENTS_ERROR = 'LOAD_EXPEDIENTS_ERROR'

export const LOAD_ASSIGNED_EXPEDIENTS_REQUEST = 'LOAD_ASSIGNED_EXPEDIENTS_REQUEST'
export const LOAD_ASSIGNED_EXPEDIENTS_SUCCESS = 'LOAD_ASSIGNED_EXPEDIENTS_SUCCESS'
export const LOAD_ASSIGNED_EXPEDIENTS_ERROR = 'LOAD_ASSIGNED_EXPEDIENTS_ERROR'

export const RESET_CREATED_EXPEDIENT = 'RESET_CREATED_EXPEDIENT'

export const UPDATE_EXPEDIENT_REQUEST = 'UPDATE_EXPEDIENT_REQUEST'
export const UPDATE_EXPEDIENT_SUCCESS = 'UPDATE_EXPEDIENT_SUCCESS'
export const UPDATE_EXPEDIENT_ERROR = 'UPDATE_EXPEDIENT_ERROR'

export const UPDATE_REPORT_REQUEST = 'UPDATE_REPORT_REQUEST'
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS'
export const UPDATE_REPORT_ERROR = 'UPDATE_REPORT_ERROR'

export const LOAD_DRAFTS_REQUEST = 'LOAD_DRAFTS_REQUEST'
export const LOAD_DRAFTS_SUCCESS = 'LOAD_DRAFTS_SUCCESS'
export const LOAD_DRAFTS_ERROR = 'LOAD_DRAFTS_ERROR'

export const EXPEDIENT_DELETED_REQUEST = 'EXPEDIENT_DELETED_REQUEST'
export const EXPEDIENT_DELETED_SUCCESS = 'EXPEDIENT_DELETED_SUCCESS'
export const EXPEDIENT_DELETED_ERROR = 'EXPEDIENT_DELETED_ERROR'

export const EXPEDIENT_DOC_REMINDER_REQUEST = 'EXPEDIENT_DOC_REMINDER_REQUEST'
export const EXPEDIENT_DOC_REMINDER_SUCCESS = 'EXPEDIENT_DOC_REMINDER_SUCCESS'
export const EXPEDIENT_DOC_REMINDER_ERROR = 'EXPEDIENT_DOC_REMINDER_ERROR'

export const REASSIGN_EXPEDIENT_REQUEST = 'REASSIGN_EXPEDIENT_REQUEST'
export const REASSIGN_EXPEDIENT_SUCCESS = 'REASSIGN_EXPEDIENT_SUCCESS'
export const REASSIGN_EXPEDIENT_ERROR = 'REASSIGN_EXPEDIENT_ERROR'

export const EXPEDIENT_NOTIFICATION_REQUEST = 'EXPEDIENT_NOTIFICATION_REQUEST'
export const EXPEDIENT_NOTIFICATION_SUCCESS = 'EXPEDIENT_NOTIFICATION_SUCCESS'
export const EXPEDIENT_NOTIFICATION_ERROR = 'EXPEDIENT_NOTIFICATION_ERROR'

export const EXPEDIENT_DELETE_FILE_REQUEST = 'EXPEDIENT_DELETE_FILE_REQUEST'
export const EXPEDIENT_DELETE_FILE_SUCCESS = 'EXPEDIENT_DELETE_FILE_SUCCESS'
export const EXPEDIENT_DELETE_FILE_ERROR = 'EXPEDIENT_DELETE_FILE_ERROR'

// #region SUBSTITUTE INVOICE
export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST'
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS'
export const GET_INVOICES_ERROR = 'GET_INVOICES_ERROR'

export const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST'
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS'
export const GET_INVOICE_ERROR = 'GET_INVOICE_ERROR'

export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST'
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS'
export const UPDATE_INVOICE_ERROR = 'UPDATE_INVOICE_ERROR'


// #region PROMOTIONS
export const GET_PROMOTIONS_REQUEST = 'GET_PROMOTIONS_REQUEST'
export const GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCCESS'
export const GET_PROMOTIONS_ERROR = 'GET_PROMOTIONS_ERROR'

export const GET_PROMOTION_REQUEST = 'GET_PROMOTION_REQUEST'
export const GET_PROMOTION_SUCCESS = 'GET_PROMOTION_SUCCESS'
export const GET_PROMOTION_ERROR = 'GET_PROMOTION_ERROR'

export const UPDATE_PROMOTION_REQUEST = 'UPDATE_PROMOTION_REQUEST'
export const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS'
export const UPDATE_PROMOTION_ERROR = 'UPDATE_PROMOTION_ERROR'

export const CREATE_PROMOTION_REQUEST = 'CREATE_PROMOTION_REQUEST'
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS'
export const CREATE_PROMOTION_ERROR = 'CREATE_PROMOTION_ERROR'

export const VALIDATE_PROMOTION_REQUEST = 'VALIDATE_PROMOTION_REQUEST'
export const VALIDATE_PROMOTION_SUCCESS = 'VALIDATE_PROMOTION_SUCCESS'
export const VALIDATE_PROMOTION_ERROR = 'VALIDATE_PROMOTION_ERROR'

export const DELETE_INVOICE_REQUEST = 'DELETED_INVOICE_REQUEST'
export const DELETE_INVOICE_SUCCESS = 'DELETED_INVOICE_SUCCESS'
export const DELETE_INVOICE_ERROR = 'DELETED_INVOICE_ERROR'

export const SEND_INVOICE_REQUEST = 'SEND_INVOICE_REQUEST'
export const SEND_INVOICE_SUCCESS = 'SEND_INVOICE_SUCCESS'
export const SEND_INVOICE_ERROR = 'SEND_INVOICE_ERROR'

// #region USER INVOICE ACCOUNT
export const ADD_USER_INVOICE_ACCOUNT_REQUEST = 'ADD_USER_INVOICE_ACCOUNT_REQUEST'
export const ADD_USER_INVOICE_ACCOUNT_SUCCESS = 'ADD_USER_INVOICE_ACCOUNT_SUCCESS'
export const ADD_USER_INVOICE_ACCOUNT_ERROR = 'ADD_USER_INVOICE_ACCOUNT_ERROR'

export const DELETE_USER_INVOICE_ACCOUNT_REQUEST = 'DELETE_USER_INVOICE_ACCOUNT_REQUEST'
export const DELETE_USER_INVOICE_ACCOUNT_SUCCESS = 'DELETE_USER_INVOICE_ACCOUNT_SUCCESS'
export const DELETE_USER_INVOICE_ACCOUNT_ERROR = 'DELETE_USER_INVOICE_ACCOUNT_ERROR'

export const UPDATE_USER_INVOICE_ACCOUNT_REQUEST = 'UPDATE_USER_INVOICE_ACCOUNT_REQUEST'
export const UPDATE_USER_INVOICE_ACCOUNT_SUCCESS = 'UPDATE_USER_INVOICE_ACCOUNT_SUCCESS'
export const UPDATE_USER_INVOICE_ACCOUNT_ERROR = 'UPDATE_USER_INVOICE_ACCOUNT_ERROR'

export const LOAD_USER_INVOICE_ACCOUNT_REQUEST = 'LOAD_USER_INVOICE_ACCOUNT_REQUEST'
export const LOAD_USER_INVOICE_ACCOUNT_SUCCESS = 'LOAD_USER_INVOICE_ACCOUNT_SUCCESS'
export const LOAD_USER_INVOICE_ACCOUNT_ERROR = 'LOAD_USER_INVOICE_ACCOUNT_ERROR'

export const LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_REQUEST = 'LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_REQUEST'
export const LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_SUCCESS = 'LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_SUCCESS'
export const LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_ERROR = 'LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_ERROR'

export const RESET_SEPA_AUTH_URL_REQUEST = 'RESET_SEPA_AUTH_URL_REQUEST'
export const RESET_SEPA_AUTH_URL_SUCCESS = 'RESET_SEPA_AUTH_URL_SUCCESS'
export const RESET_SEPA_AUTH_URL_ERROR = 'RESET_SEPA_AUTH_URL_ERROR'

export const LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_REQUEST = 'LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_REQUEST'
export const LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_SUCCESS = 'LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_SUCCESS'
export const LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_ERROR = 'LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_ERROR'

export const LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_REQUEST = 'LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_REQUEST'
export const LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_SUCCESS = 'LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_SUCCESS'
export const LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_ERROR = 'LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_ERROR'

// #region REVIEWS
export const GET_EXPEDIENT_REVIEW_REQUEST = 'GET_EXPEDIENT_REVIEW_REQUEST'
export const GET_EXPEDIENT_REVIEW_SUCCESS = 'GET_EXPEDIENT_REVIEW_SUCCESS'
export const GET_EXPEDIENT_REVIEW_ERROR = 'GET_EXPEDIENT_REVIEW_ERROR'

export const CREATE_SUBSTITUTE_REVIEW_REQUEST = 'CREATE_SUBSTITUTE_REVIEW_REQUEST'
export const CREATE_SUBSTITUTE_REVIEW_SUCCESS = 'CREATE_SUBSTITUTE_REVIEW_SUCCESS'
export const CREATE_SUBSTITUTE_REVIEW_ERROR = 'CREATE_SUBSTITUTE_REVIEW_ERROR'

export const UPDATE_SUBSTITUTE_REVIEW_REQUEST = 'UPDATE_SUBSTITUTE_REVIEW_REQUEST'
export const UPDATE_SUBSTITUTE_REVIEW_SUCCESS = 'UPDATE_SUBSTITUTE_REVIEW_SUCCESS'
export const UPDATE_SUBSTITUTE_REVIEW_ERROR = 'UPDATE_SUBSTITUTE_REVIEW_ERROR'

export const GET_SUBSTITUTE_REVIEWS_REQUEST = 'GET_SUBSTITUTE_REVIEWS_REQUEST'
export const GET_SUBSTITUTE_REVIEWS_SUCCESS = 'GET_SUBSTITUTE_REVIEWS_SUCCESS'
export const GET_SUBSTITUTE_REVIEWS_ERROR = 'GET_SUBSTITUTE_REVIEWS_ERROR'

// #region JUDICIAL BRIEFS
export const GET_JUDICIAL_BRIEF_REQUEST = 'GET_JUDICIAL_BRIEF_REQUEST'
export const GET_JUDICIAL_BRIEF_SUCCESS = 'GET_JUDICIAL_BRIEF_SUCCESS'
export const GET_JUDICIAL_BRIEF_ERROR = 'GET_JUDICIAL_BRIEF_ERROR'

export const GET_JUDICIAL_BRIEFS_REQUEST = 'GET_JUDICIAL_BRIEFS_REQUEST'
export const GET_JUDICIAL_BRIEFS_SUCCESS = 'GET_JUDICIAL_BRIEFS_SUCCESS'
export const GET_JUDICIAL_BRIEFS_ERROR = 'GET_JUDICIAL_BRIEFS_ERROR'

export const CREATE_JUDICIAL_BRIEF_REQUEST = 'CREATE_JUDICIAL_BRIEF_REQUEST'
export const CREATE_JUDICIAL_BRIEF_SUCCESS = 'CREATE_JUDICIAL_BRIEF_SUCCESS'
export const CREATE_JUDICIAL_BRIEF_ERROR = 'CREATE_JUDICIAL_BRIEF_ERROR'

export const UPDATE_JUDICIAL_BRIEF_REQUEST = 'UPDATE_JUDICIAL_BRIEF_REQUEST'
export const UPDATE_JUDICIAL_BRIEF_SUCCESS = 'UPDATE_JUDICIAL_BRIEF_SUCCESS'
export const UPDATE_JUDICIAL_BRIEF_ERROR = 'UPDATE_JUDICIAL_BRIEF_ERROR'

export const DELETE_JUDICIAL_BRIEF_REQUEST = 'DELETE_JUDICIAL_BRIEF_REQUEST'
export const DELETE_JUDICIAL_BRIEF_SUCCESS = 'DELETE_JUDICIAL_BRIEF_SUCCESS'
export const DELETE_JUDICIAL_BRIEF_ERROR = 'DELETE_JUDICIAL_BRIEF_ERROR'

// #region REMITTANCES
export const GET_REMITTANCES_REQUEST = 'GET_REMITTANCES_REQUEST'
export const GET_REMITTANCES_SUCCESS = 'GET_REMITTANCES_SUCCESS'
export const GET_REMITTANCES_ERROR = 'GET_REMITTANCES_ERROR'

export const SET_REMITTANCE_PROCESSED_REQUEST = 'SET_REMITTANCE_PROCESSED_REQUEST'
export const SET_REMITTANCE_PROCESSED_SUCCESS = 'SET_REMITTANCE_PROCESSED_SUCCESS'
export const SET_REMITTANCE_PROCESSED_ERROR = 'SET_REMITTANCE_PROCESSED_ERROR'

export const REPEAT_REMITTANCE_REQUEST = 'REPEAT_REMITTANCE_REQUEST'
export const REPEAT_REMITTANCE_SUCCESS = 'REPEAT_REMITTANCE_SUCCESS'
export const REPEAT_REMITTANCE_ERROR = 'REPEAT_REMITTANCE_ERROR'

export const REPEAT_INVOICE_REMITTANCE_REQUEST = 'REPEAT_INVOICE_REMITTANCE_REQUEST'
export const REPEAT_INVOICE_REMITTANCE_SUCCESS = 'REPEAT_INVOICE_REMITTANCE_SUCCESS'
export const REPEAT_INVOICE_REMITTANCE_ERROR = 'REPEAT_INVOICE_REMITTANCE_ERROR'

export const ROLLBACK_REMITTANCE_REQUEST = 'ROLLBACK_REMITTANCE_REQUEST'
export const ROLLBACK_REMITTANCE_SUCCESS = 'ROLLBACK_REMITTANCE_SUCCESS'
export const ROLLBACK_REMITTANCE_ERROR = 'ROLLBACK_REMITTANCE_ERROR'

export const ROLLBACK_INVOICE_REMITTANCE_REQUEST = 'ROLLBACK_REMITTANCE_REQUEST'
export const ROLLBACK_INVOICE_REMITTANCE_SUCCESS = 'ROLLBACK_REMITTANCE_SUCCESS'
export const ROLLBACK_INVOICE_REMITTANCE_ERROR = 'ROLLBACK_REMITTANCE_ERROR'

export const IMPORT_HOLDED_REMITTANCE_REQUEST = 'IMPORT_HOLDED_REMITTANCE_REQUEST'
export const IMPORT_HOLDED_REMITTANCE_SUCCESS = 'IMPORT_HOLDED_REMITTANCE_SUCCESS'
export const IMPORT_HOLDED_REMITTANCE_ERROR = 'IMPORT_HOLDED_REMITTANCE_ERROR'

// #region PUBLIC API
export const GET_API_KEY_REQUEST = 'GET_API_KEY_REQUEST'
export const GET_API_KEY_SUCCESS = 'GET_API_KEY_SUCCESS'
export const GET_API_KEY_ERROR = 'GET_API_KEY_ERROR'

export const GET_LAST_API_KEY_DATE_REQUEST = 'GET_LAST_API_KEY_DATE_REQUEST'
export const GET_LAST_API_KEY_DATE_SUCCESS = 'GET_LAST_API_KEY_DATE_SUCCESS'
export const GET_LAST_API_KEY_DATE_ERROR = 'GET_LAST_API_KEY_DATE_ERROR'

export const DELETE_LAST_API_KEY_DATE_REQUEST = 'DELETE_LAST_API_KEY_DATE_REQUEST'
export const DELETE_LAST_API_KEY_DATE_SUCCESS = 'DELETE_LAST_API_KEY_DATE_SUCCESS'
export const DELETE_LAST_API_KEY_DATE_ERROR = 'DELETE_LAST_API_KEY_DATE_ERROR'

export const RESET_API_KEY = 'RESET_API_KEY'

// #region NOTARY
export const CREATE_NOTARY_REQUEST = 'CREATE_NOTARY_REQUEST'
export const CREATE_NOTARY_SUCCESS = 'CREATE_NOTARY_SUCCESS'
export const CREATE_NOTARY_ERROR = 'CREATE_NOTARY_ERROR'

// #region ISSUE
export const GET_ISSUE_REQUEST = 'GET_ISSUE_REQUEST'
export const GET_ISSUE_SUCCESS = 'GET_ISSUE_SUCCESS'
export const GET_ISSUE_ERROR = 'GET_ISSUE_ERROR'

export const CREATE_ISSUE_REQUEST = 'CREATE_ISSUE_REQUEST'
export const CREATE_ISSUE_SUCCESS = 'CREATE_ISSUE_SUCCESS'
export const CREATE_ISSUE_ERROR = 'CREATE_ISSUE_ERROR'

export const UPDATE_ISSUE_REQUEST = 'UPDATE_ISSUE_REQUEST'
export const UPDATE_ISSUE_SUCCESS = 'UPDATE_ISSUE_SUCCESS'
export const UPDATE_ISSUE_ERROR = 'UPDATE_ISSUE_ERROR'

// #region SHORT_TERM_PROJECT
export const CREATE_PROJECT_SHORT_TERM_REQUEST = 'CREATE_PROJECT_SHORT_TERM_REQUEST'
export const CREATE_PROJECT_SHORT_TERM_SUCCESS = 'CREATE_PROJECT_SHORT_TERM_SUCCESS'
export const CREATE_PROJECT_SHORT_TERM_ERROR = 'CREATE_PROJECT_SHORT_TERM_ERROR'

export const LOAD_PROJECT_SHORT_TERM_REQUEST = 'LOAD_PROJECT_SHORT_TERM_REQUEST'
export const LOAD_PROJECT_SHORT_TERM_SUCCESS = 'LOAD_PROJECT_SHORT_TERM_SUCCESS'
export const LOAD_PROJECT_SHORT_TERM_ERROR = 'LOAD_PROJECT_SHORT_TERM_ERROR'

export const LOAD_PROJECTS_SHORT_TERM_REQUEST = 'LOAD_PROJECTS_SHORT_TERM_REQUEST'
export const LOAD_PROJECTS_SHORT_TERM_SUCCESS = 'LOAD_PROJECTS_SHORT_TERM_SUCCESS'
export const LOAD_PROJECTS_SHORT_TERM_ERROR = 'LOAD_PROJECTS_SHORT_TERM_ERROR'

export const LOAD_ASSIGNED_PROJECTS_SHORT_TERM_REQUEST = 'LOAD_ASSIGNED_PROJECTS_SHORT_TERM_REQUEST'
export const LOAD_ASSIGNED_PROJECTS_SHORT_TERM_SUCCESS = 'LOAD_ASSIGNED_PROJECTS_SHORT_TERM_SUCCESS'
export const LOAD_ASSIGNED_PROJECTS_SHORT_TERM_ERROR = 'LOAD_ASSIGNED_PROJECTS_SHORT_TERM_ERROR'

export const UPDATE_PROJECT_SHORT_TERM_REQUEST = 'UPDATE_PROJECT_SHORT_TERM_REQUEST'
export const UPDATE_PROJECT_SHORT_TERM_SUCCESS = 'UPDATE_PROJECT_SHORT_TERM_SUCCESS'
export const UPDATE_PROJECT_SHORT_TERM_ERROR = 'UPDATE_PROJECT_SHORT_TERM_ERROR'

export const DELETE_PROJECT_SHORT_TERM_REQUEST = 'DELETE_PROJECT_SHORT_TERM_REQUEST'
export const DELETE_PROJECT_SHORT_TERM_SUCCESS = 'DELETEPROJECT_SHORT_TERM_SUCCESS'
export const DELETE_PROJECT_SHORT_TERM_ERROR = 'DELETE_PROJECT_SHORT_TERM_ERROR'

export const PROJECT_SHORT_DOC_RECLAMATION_REQUEST = 'PROJECT_SHORT_DOC_RECLAMATION_REQUEST'
export const PROJECT_SHORT_DOC_RECLAMATION_SUCCESS = 'PROJECT_SHORT_DOC_RECLAMATION_SUCCESS'
export const PROJECT_SHORT_DOC_RECLAMATION_ERROR = 'PROJECT_SHORT_DOC_RECLAMATION_ERROR'

export const REASSIGN_PROJECTST_REQUEST = 'REASSIGN_PROJECTST_REQUEST'
export const REASSIGN_PROJECTST_SUCCESS = 'REASSIGN_PROJECTST_SUCCESS'
export const REASSIGN_PROJECTST_ERROR = 'REASSIGN_PROJECTST_ERROR'

export const PROJECT_SHORT_TERM_DELETE_FILE_REQUEST = 'PROJECT_SHORT_TERM_DELETE_FILE_REQUEST'
export const PROJECT_SHORT_TERM_DELETE_FILE_SUCCESS = 'PROJECT_SHORT_TERM_DELETE_FILE_SUCCESS'
export const PROJECT_SHORT_TERM_DELETE_FILE_ERROR = 'PROJECT_SHORT_TERM_DELETE_FILE_ERROR'

// #region CAPABILITIES
export const CREATE_CAPABILITIES_REQUEST = 'CREATE_CAPABILITIES_REQUEST'
export const CREATE_CAPABILITIES_SUCCESS = 'CREATE_CAPABILITIES_SUCCESS'
export const CREATE_CAPABILITIES_ERROR = 'CREATE_CAPABILITIES_ERROR'

// #region LONG_TERM_PROJECT
export const CREATE_PROJECT_LONG_TERM_REQUEST = 'CREATE_PROJECT_LONG_TERM_REQUEST'
export const CREATE_PROJECT_LONG_TERM_SUCCESS = 'CREATE_PROJECT_LONG_TERM_SUCCESS'
export const CREATE_PROJECT_LONG_TERM_ERROR = 'CREATE_PROJECT_LONG_TERM_ERROR'

export const LOAD_PROJECT_LONG_TERM_REQUEST = 'LOAD_PROJECT_LONG_TERM_REQUEST'
export const LOAD_PROJECT_LONG_TERM_SUCCESS = 'LOAD_PROJECT_LONG_TERM_SUCCESS'
export const LOAD_PROJECT_LONG_TERM_ERROR = 'LOAD_PROJECT_LONG_TERM_ERROR'

export const LOAD_PROJECTS_LONG_TERM_REQUEST = 'LOAD_PROJECTS_LONG_TERM_REQUEST'
export const LOAD_PROJECTS_LONG_TERM_SUCCESS = 'LOAD_PROJECTS_LONG_TERM_SUCCESS'
export const LOAD_PROJECTS_LONG_TERM_ERROR = 'LOAD_PROJECTS_LONG_TERM_ERROR'

export const LOAD_ASSIGNED_PROJECTS_LONG_TERM_REQUEST = 'LOAD_ASSIGNED_PROJECTS_LONG_TERM_REQUEST'
export const LOAD_ASSIGNED_PROJECTS_LONG_TERM_SUCCESS = 'LOAD_ASSIGNED_PROJECTS_LONG_TERM_SUCCESS'
export const LOAD_ASSIGNED_PROJECTS_LONG_TERM_ERROR = 'LOAD_ASSIGNED_PROJECTS_LONG_TERM_ERROR'

export const UPDATE_PROJECT_LONG_TERM_REQUEST = 'UPDATE_PROJECT_LONG_TERM_REQUEST'
export const UPDATE_PROJECT_LONG_TERM_SUCCESS = 'UPDATE_PROJECT_LONG_TERM_SUCCESS'
export const UPDATE_PROJECT_LONG_TERM_ERROR = 'UPDATE_PROJECT_LONG_TERM_ERROR'

export const DELETE_PROJECT_LONG_TERM_REQUEST = 'DELETE_PROJECT_LONG_TERM_REQUEST'
export const DELETE_PROJECT_LONG_TERM_SUCCESS = 'DELETEPROJECT_LONG_TERM_SUCCESS'
export const DELETE_PROJECT_LONG_TERM_ERROR = 'DELETE_PROJECT_LONG_TERM_ERROR'

export const PROJECT_LONG_DOC_RECLAMATION_REQUEST = 'PROJECT_LONG_DOC_RECLAMATION_REQUEST'
export const PROJECT_LONG_DOC_RECLAMATION_SUCCESS = 'PROJECT_LONG_DOC_RECLAMATION_SUCCESS'
export const PROJECT_LONG_DOC_RECLAMATION_ERROR = 'PROJECT_LONG_DOC_RECLAMATION_ERROR'

export const REASSIGN_PROJECT_LONG_TERM_REQUEST = 'REASSIGN_PROJECT_LONG_TERM_REQUEST'
export const REASSIGN_PROJECT_LONG_TERM_SUCCESS = 'REASSIGN_PROJECT_LONG_TERM_SUCCESS'
export const REASSIGN_PROJECT_LONG_TERM_ERROR = 'REASSIGN_PROJECT_LONG_TERM_ERROR'

export const APPLY_PROJECT_LONG_TERM_REQUEST = 'APPLY_PROJECT_LONG_TERM_REQUEST'
export const APPLY_PROJECT_LONG_TERM_SUCCESS = 'APPLY_PROJECT_LONG_TERM_SUCCESS'
export const APPLY_PROJECT_LONG_TERM_ERROR = 'APPLY_PROJECT_LONG_TERM_ERROR'

export const ASSIGN_PROJECT_LONG_TERM_REQUEST = 'ASSIGN_PROJECT_LONG_TERM_REQUEST'
export const ASSIGN_PROJECT_LONG_TERM_SUCCESS = 'ASSIGN_PROJECT_LONG_TERM_SUCCESS'
export const ASSIGN_PROJECT_LONG_TERM_ERROR = 'ASSIGN_PROJECT_LONG_TERM_ERROR'

export const CREATE_PROJECT_LONG_MEETING_REQUEST = 'CREATE_PROJECT_LONG_MEETING_REQUEST'
export const CREATE_PROJECT_LONG_MEETING_SUCCESS = 'CREATE_PROJECT_LONG_MEETING_SUCCESS'
export const CREATE_PROJECT_LONG_MEETING_ERROR = 'CREATE_PROJECT_LONG_MEETING_ERROR'

export const DECLINE_PROJECT_LONG_TERM_CANDIDATE_REQUEST = "DECLINE_PROJECT_LONG_TERM_CANDIDATE_REQUEST"
export const DECLINE_PROJECT_LONG_TERM_CANDIDATE_SUCCESS = "DECLINE_PROJECT_LONG_TERM_CANDIDATE_SUCCESS"
export const DECLINE_PROJECT_LONG_TERM_CANDIDATE_ERROR = "DECLINE_PROJECT_LONG_TERM_CANDIDATE_ERROR"

export const CANCEL_PROJECT_LONG_TERM_REQUEST = "CANCEL_PROJECT_LONG_TERM_REQUEST"
export const CANCEL_PROJECT_LONG_TERM_SUCCESS = "CANCEL_PROJECT_LONG_TERM_SUCCESS"
export const CANCEL_PROJECT_LONG_TERM_ERROR = "CANCEL_PROJECT_LONG_TERM_ERROR"

// PROCURA EXPEDIENT
export const CREATE_PROCURA_REQUEST = 'CREATE_PROCURA_REQUEST'
export const CREATE_PROCURA_SUCCESS = 'CREATE_PROCURA_SUCCESS'
export const CREATE_PROCURA_ERROR = 'CREATE_PROCURA_ERROR'

export const LOAD_PROCURAS_REQUEST = 'LOAD_PROCURAS_REQUEST'
export const LOAD_PROCURAS_SUCCESS = 'LOAD_PROCURAS_SUCCESS'
export const LOAD_PROCURAS_ERROR = 'LOAD_PROCURAS_ERROR'

export const LOAD_PROCURA_REQUEST = 'LOAD_PROCURA_REQUEST'
export const LOAD_PROCURA_SUCCESS = 'LOAD_PROCURA_SUCCESS'
export const LOAD_PROCURA_ERROR = 'LOAD_PROCURA_ERROR'

export const LOAD_ASSIGNED_PROCURA_REQUEST = 'LOAD_ASSIGNED_PROCURA_REQUEST'
export const LOAD_ASSIGNED_PROCURA_SUCCESS = 'LOAD_ASSIGNED_PROCURA_SUCCESS'
export const LOAD_ASSIGNED_PROCURA_ERROR = 'LOAD_ASSIGNED_PROCURA_ERROR'

export const RESET_CREATED_PROCURA = 'RESET_CREATED_PROCURA'
export const CLEAR_PROCURA = 'CLEAR_PROCURA'

export const UPDATE_PROCURA_REQUEST = 'UPDATE_PROCURA_REQUEST'
export const UPDATE_PROCURA_SUCCESS = 'UPDATE_PROCURA_SUCCESS'
export const UPDATE_PROCURA_ERROR = 'UPDATE_PROCURA_ERROR'

export const UPDATE_PROCURA_REPORT_REQUEST = 'UPDATE_PROCURA_REPORT_REQUEST'
export const UPDATE_PROCURA_REPORT_SUCCESS = 'UPDATE_PROCURA_REPORT_SUCCESS'
export const UPDATE_PROCURA_REPORT_ERROR = 'UPDATE_PROCURA_REPORT_ERROR'

export const LOAD_PROCURA_DRAFTS_REQUEST = 'LOAD_PROCURA_DRAFTS_REQUEST'
export const LOAD_PROCURA_DRAFTS_SUCCESS = 'LOAD_PROCURA_DRAFTS_SUCCESS'
export const LOAD_PROCURA_DRAFTS_ERROR = 'LOAD_PROCURA_DRAFTS_ERROR'

export const PROCURA_DELETED_REQUEST = 'PROCURA_DELETED_REQUEST'
export const PROCURA_DELETED_SUCCESS = 'PROCURA_DELETED_SUCCESS'
export const PROCURA_DELETED_ERROR = 'PROCURA_DELETED_ERROR'

export const PROCURA_DOC_REMINDER_REQUEST = 'PROCURA_DOC_REMINDER_REQUEST'
export const PROCURA_DOC_REMINDER_SUCCESS = 'PROCURA_DOC_REMINDER_SUCCESS'
export const PROCURA_DOC_REMINDER_ERROR = 'PROCURA_DOC_REMINDER_ERROR'

export const REASSIGN_PROCURA_REQUEST = 'REASSIGN_PROCURA_REQUEST'
export const REASSIGN_PROCURA_SUCCESS = 'REASSIGN_PROCURA_SUCCESS'
export const REASSIGN_PROCURA_ERROR = 'REASSIGN_PROCURA_ERROR'

export const PROCURA_NOTIFICATION_REQUEST = 'PROCURA_NOTIFICATION_REQUEST'
export const PROCURA_NOTIFICATION_SUCCESS = 'PROCURA_NOTIFICATION_SUCCESS'
export const PROCURA_NOTIFICATION_ERROR = 'PROCURA_NOTIFICATION_ERROR'

export const PROCURA_DELETE_FILE_REQUEST = 'PROCURA_DELETE_FILE_REQUEST'
export const PROCURA_DELETE_FILE_SUCCESS = 'PROCURA_DELETE_FILE_SUCCESS'
export const PROCURA_DELETE_FILE_ERROR = 'PROCURA_DELETE_FILE_ERROR'

// DRAFTS
export const LOAD_PROJECTST_DRAFTS_REQUEST = 'LOAD_PROJECTST_DRAFTS_REQUEST'
export const LOAD_PROJECTST_DRAFTS_SUCCESS = 'LOAD_PROJECTST_DRAFTS_SUCCESS'
export const LOAD_PROJECTST_DRAFTS_ERROR = 'LOAD_PROJECTST_DRAFTS_ERROR'

// POSSESSIONS
export const CREATE_POSSESSION_REQUEST = 'CREATE_POSSESSION_REQUEST'
export const CREATE_POSSESSION_SUCCESS = 'CREATE_POSSESSION_SUCCESS'
export const CREATE_POSSESSION_ERROR = 'CREATE_POSSESSION_ERROR'

// COMMUNITY_PARTNER
export const GET_COMMUNITY_PARTNERS_REQUEST = 'GET_COMMUNITY_PARTNERS_REQUEST';
export const GET_COMMUNITY_PARTNERS_SUCCESS = 'GET_COMMUNITY_PARTNERS_SUCCESS';
export const GET_COMMUNITY_PARTNERS_ERROR = 'GET_COMMUNITY_PARTNERS_ERROR';

export const GET_COMMUNITY_PARTNER_REQUEST = 'GET_COMMUNITY_PARTNER_REQUEST';
export const GET_COMMUNITY_PARTNER_SUCCESS = 'GET_COMMUNITY_PARTNER_SUCCESS';
export const GET_COMMUNITY_PARTNER_ERROR = 'GET_COMMUNITY_PARTNER_ERROR';

export const CREATE_COMMUNITY_PARTNER_REQUEST = 'CREATE_COMMUNITY_PARTNER_REQUEST';
export const CREATE_COMMUNITY_PARTNER_SUCCESS = 'CREATE_COMMUNITY_PARTNER_SUCCESS';
export const CREATE_COMMUNITY_PARTNER_ERROR = 'CREATE_COMMUNITY_PARTNER_ERROR';

export const UPDATE_COMMUNITY_PARTNER_REQUEST = 'UPDATE_COMMUNITY_PARTNER_REQUEST';
export const UPDATE_COMMUNITY_PARTNER_SUCCESS = 'UPDATE_COMMUNITY_PARTNER_SUCCESS';
export const UPDATE_COMMUNITY_PARTNER_ERROR = 'UPDATE_COMMUNITY_PARTNER_ERROR';

export const DELETE_COMMUNITY_PARTNER_REQUEST = 'DELETE_COMMUNITY_PARTNER_REQUEST';
export const DELETE_COMMUNITY_PARTNER_SUCCESS = 'DELETE_COMMUNITY_PARTNER_SUCCESS';
export const DELETE_COMMUNITY_PARTNER_ERROR = 'DELETE_COMMUNITY_PARTNER_ERROR';

// SPECIAL PRICES
export const GET_SPECIAL_PRICES_REQUEST = 'GET_SPECIAL_PRICES_REQUEST'
export const GET_SPECIAL_PRICES_SUCCESS = 'GET_SPECIAL_PRICES_SUCCESS'
export const GET_SPECIAL_PRICES_ERROR = 'GET_SPECIAL_PRICES_ERROR'

export const GET_SPECIAL_PRICE_REQUEST = 'GET_SPECIAL_PRICE_REQUEST'
export const GET_SPECIAL_PRICE_SUCCESS = 'GET_SPECIAL_PRICE_SUCCESS'
export const GET_SPECIAL_PRICE_ERROR = 'GET_SPECIAL_PRICE_ERROR'

export const CREATE_SPECIAL_PRICE_REQUEST = 'CREATE_SPECIAL_PRICE_REQUEST'
export const CREATE_SPECIAL_PRICE_SUCCESS = 'CREATE_SPECIAL_PRICE_SUCCESS'
export const CREATE_SPECIAL_PRICE_ERROR = 'CREATE_SPECIAL_PRICE_ERROR'

export const UPDATE_SPECIAL_PRICE_REQUEST = 'UPDATE_SPECIAL_PRICE_REQUEST'
export const UPDATE_SPECIAL_PRICE_SUCCESS = 'UPDATE_SPECIAL_PRICE_SUCCESS'
export const UPDATE_SPECIAL_PRICE_ERROR = 'UPDATE_SPECIAL_PRICE_ERROR'

export const DELETE_SPECIAL_PRICE_REQUEST = 'DELETE_SPECIAL_PRICE_REQUEST'
export const DELETE_SPECIAL_PRICE_SUCCESS = 'DELETE_SPECIAL_PRICE_SUCCESS'
export const DELETE_SPECIAL_PRICE_ERROR = 'DELETE_SPECIAL_PRICE_ERROR'

// URGENT ISSUES
export const GET_URGENT_ISSUES_LIST_REQUEST = 'GET_URGENT_ISSUES_LIST_REQUEST'
export const GET_URGENT_ISSUES_LIST_SUCCESS = 'GET_URGENT_ISSUES_LIST_SUCCESS'
export const GET_URGENT_ISSUES_LIST_ERROR = 'GET_URGENT_ISSUES_LIST_ERROR'

// #region PIGEON AI
export const PIGEONAI_UPLOAD_FILES_REQUEST = 'PIGEONAI_UPLOAD_FILES_REQUEST'
export const PIGEONAI_UPLOAD_FILES_SUCCESS = 'PIGEONAI_UPLOAD_FILES_SUCCESS'
export const PIGEONAI_UPLOAD_FILES_ERROR = 'PIGEONAI_UPLOAD_FILES_ERROR'

export const PIGEONAI_DOWNLOAD_FILE_REQUEST = 'PIGEONAI_DOWNLOAD_FILE_REQUEST'
export const PIGEONAI_DOWNLOAD_FILE_SUCCESS = 'PIGEONAI_DOWNLOAD_FILE_SUCCESS'
export const PIGEONAI_DOWNLOAD_FILE_ERROR = 'PIGEONAI_DOWNLOAD_FILE_ERROR'

export const PIGEONAI_GET_STATUS_REQUEST = 'PIGEONAI_GET_STATUS_REQUEST'
export const PIGEONAI_GET_STATUS_SUCCESS = 'PIGEONAI_GET_STATUS_SUCCESS'
export const PIGEONAI_GET_STATUS_ERROR = 'PIGEONAI_GET_STATUS_ERROR'

export const RESET_DOWNLOAD_READY = "RESET_DOWNLOAD_READY"

export const PIGEONAI_LOAD_INSTRUCTAS_REQUEST = 'PIGEONAI_LOAD_INSTRUCTAS_REQUEST'
export const PIGEONAI_LOAD_INSTRUCTAS_SUCCESS = 'PIGEONAI_LOAD_INSTRUCTAS_SUCCESS'
export const PIGEONAI_LOAD_INSTRUCTAS_ERROR = 'PIGEONAI_LOAD_INSTRUCTAS_ERROR'

// #region NOTIFICATIONS
export const LOAD_USER_REMINDERS_REQUEST = 'LOAD_USER_REMINDERS_REQUEST';
export const LOAD_USER_REMINDERS_SUCCESS = 'LOAD_USER_REMINDERS_SUCCESS';
export const LOAD_USER_REMINDERS_ERROR = 'LOAD_USER_REMINDERS_ERROR';

export const UPDATE_USER_REMINDERS_STATUS_REQUEST = "UPDATE_USER_REMINDERS_STATUS_REQUEST"
export const UPDATE_USER_REMINDERS_STATUS_SUCCESS = "UPDATE_USER_REMINDERS_STATUS_SUCCESS"
export const UPDATE_USER_REMINDERS_STATUS_ERROR = "UPDATE_USER_REMINDERS_STATUS_ERROR"

export const CREATE_USER_REMINDER_STATUS_REQUEST = "CREATE_USER_REMINDER_STATUS_REQUEST"
export const CREATE_USER_REMINDER_STATUS_SUCCESS = "CREATE_USER_REMINDER_STATUS_SUCCESS"
export const CREATE_USER_REMINDER_STATUS_ERROR = "CREATE_USER_REMINDER_STATUS_ERROR"

// #region PROJECT CANDIDATE

export const DELETE_PROJECT_CANDIDATE_REQUEST = 'DELETE_PROJECT_CANDIDATE_REQUEST'
export const DELETE_PROJECT_CANDIDATE_SUCCESS = 'DELETE_PROJECT_CANDIDATE_SUCCESS'
export const DELETE_PROJECT_CANDIDATE_ERROR = 'DELETE_PROJECT_CANDIDATE_ERROR'

export const UPDATE_PROJECT_CANDIDATE_REQUEST = 'UPDATE_PROJECT_CANDIDATE_REQUEST'
export const UPDATE_PROJECT_CANDIDATE_SUCCESS = 'UPDATE_PROJECT_CANDIDATE_SUCCESS'
export const UPDATE_PROJECT_CANDIDATE_ERROR = 'UPDATE_PROJECT_CANDIDATE_ERROR'

// #region DASHBOARD

export const LOAD_ADMIN_DASHBOARD_REQUEST = 'LOAD_ADMIN_DASHBOARD_REQUEST'
export const LOAD_ADMIN_DASHBOARD_SUCCESS = 'LOAD_ADMIN_DASHBOARD_SUCCESS'
export const LOAD_ADMIN_DASHBOARD_ERROR = 'LOAD_ADMIN_DASHBOARD_ERROR'

export const LOAD_CLIENT_DASHBOARD_REQUEST = 'LOAD_CLIENT_DASHBOARD_REQUEST'
export const LOAD_CLIENT_DASHBOARD_SUCCESS = 'LOAD_CLIENT_DASHBOARD_SUCCESS'
export const LOAD_CLIENT_DASHBOARD_ERROR = 'LOAD_CLIENT_DASHBOARD_ERROR'

export const REFRESH_CLIENT_DASHBOARD_REQUEST = 'REFRESH_CLIENT_DASHBOARD_REQUEST'
export const REFRESH_CLIENT_DASHBOARD_SUCCESS = 'REFRESH_CLIENT_DASHBOARD_SUCCESS'
export const REFRESH_CLIENT_DASHBOARD_ERROR = 'REFRESH_CLIENT_DASHBOARD_ERROR'

export const LOAD_SUBSTITUTE_DASHBOARD_REQUEST = 'LOAD_SUBSTITUTE_DASHBOARD_REQUEST'
export const LOAD_SUBSTITUTE_DASHBOARD_SUCCESS = 'LOAD_SUBSTITUTE_DASHBOARD_SUCCESS'
export const LOAD_SUBSTITUTE_DASHBOARD_ERROR = 'LOAD_SUBSTITUTE_DASHBOARD_ERROR'
