import React, { useState, useEffect } from 'react'
import PageLayout from '../layouts/Page'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Label,
  InputGroup,
  FormGroup,
} from 'reactstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Loading from '../components/common/Loading'
import Select from 'react-select'
import { judicialParties } from '../services/judicialParties'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { PasswordEye } from '../components/PasswordEye'
import pigeonsLetters from '../assets/images/37.png'
import { createClient } from '../redux/actions/auth'

// prettier-ignore
const REGEXP = '(^[A-Za-zÀ-ÖØ-öø-ÿ]+)(\\s{0,1})([A-Za-zÀ-ÖØ-öø-ÿ]+)$'

const SubstituteSignUpPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { activeUser, loading } = useSelector((state) => state.auth)
  const [searchParams, setSearchParams] = useState({})
  const [isProcurador, setIsProcurador] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    last_name: '',
    email: '',
    phone: '',
    is_procurador: false,
    is_substitute: false,
    password: '',
    substitute_judicial_parties: [],
    substitute_assignments_type: ['hearing'],
  })
  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    const query = new URLSearchParams(location.search.replace('?', ''))
    if (query && (query.utm_source || query.utm_campaign || query.utm_medium)) {
      setSearchParams({
        utm_source: query.utm_source || '',
        utm_medium: query.utm_medium || '',
        utm_campaign: query.utm_campaign || '',
      })
    }
  }, [location.search])

  useEffect(() => {
    if (activeUser && activeUser._id) {
      navigate('/thanks-substitute')
    }
  }, [activeUser, navigate])

  useEffect(() => {
    const validateForm = () => {
      return (
        formValues.name.trim() &&
        formValues.last_name.trim() &&
        formValues.email.trim() &&
        formValues.phone.trim() &&
        formValues.password.trim() &&
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W|_]).{8,}$/.test(
          formValues.password
        )
      )
    }

    setIsFormValid(validateForm())
  }, [formValues])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handlePartiesChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value)
    setFormValues((prevState) => ({
      ...prevState,
      substitute_judicial_parties: selectedIds,
    }))
  }

  const handleChangeColaborationType = (type) => {
    setFormValues((prevState) => ({
      ...prevState,
      is_procurador: type === 'procurador',
      is_substitute: type === 'substitute',
    }))
    setIsProcurador(type === 'procurador')
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const user = { ...formValues, role: 'client' }

    if (searchParams.utm_source) {
      user['utm_source'] = searchParams.utm_source
    }
    if (searchParams.utm_medium) {
      user['utm_medium'] = searchParams.utm_medium
    }
    if (searchParams.utm_campaign) {
      user['utm_campaign'] = searchParams.utm_campaign
    }

    dispatch(createClient({ user }))
  }

  const handleInvalidSubmit = () => {
    setIsFormValid(false)
  }

  const roles = ['substitute', 'procurador']

  return (
    <PageLayout className="text-center">
      <Container fluid className="vh-100 d-flex align-items-center">
        <Row className="w-100 align-items-center">
          <Col
            md={6}
            className="d-flex flex-column align-items-center justify-content-center p-4"
          >
            {loading ? (
              <Loading loading={loading} />
            ) : (
              <>
                <div className="text-center mt-4 mb-4">
                  <h1 className="h2">
                    <span role="img" aria-label="rocket">
                      Forma parte de nuestra red 🚀
                    </span>
                  </h1>
                  <p className="lead">
                    ¿Eres abogado o procurador? Regístrate en Legal Pigeon y
                    colabora en la red de abogados más grande de España.
                  </p>
                </div>

                <Card className="w-100 shadow-sm" style={{ maxWidth: '500px' }}>
                  <CardBody>
                    <div className="mb-4">
                      <Label className="h4 text-center mb-3">
                        ¿Eres abogado o procurador?
                      </Label>
                      <Row className="justify-content-center">
                        {roles.map((role) => {
                          const isSelected =
                            (isProcurador === true && role === 'procurador') ||
                            (isProcurador === false && role === 'substitute')
                          const borderClass = isSelected
                            ? 'border border-dark'
                            : 'border-0'

                          return (
                            <Button
                              key={role}
                              className={`d-flex flex-row ${borderClass} p-3 mx-2`}
                              type="button"
                              onClick={() => handleChangeColaborationType(role)}
                              color="light"
                              outline
                              style={{
                                borderRadius: '15px',
                                width: '150px',
                                height: '100px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <div>
                                <FontAwesomeIcon
                                  className={
                                    isSelected ? 'text-success' : 'text-primary'
                                  }
                                  size="2x"
                                  icon={faCircleCheck}
                                />
                              </div>

                              <h5 className="ms-3 mt-2">
                                {role === 'substitute'
                                  ? 'Abogado'
                                  : 'Procurador'}
                              </h5>
                            </Button>
                          )
                        })}
                      </Row>
                    </div>
                    {(formValues.is_procurador || formValues.is_substitute) && (
                      <AvForm
                        onValidSubmit={handleSubmit}
                        onInvalidSubmit={handleInvalidSubmit}
                      >
                        <div className="mb-3">
                          <Label>Nombre</Label>
                          <AvField
                            type="text"
                            name="name"
                            className="form-control"
                            value={formValues.name}
                            onChange={handleInputChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'El nombre es obligatorio',
                              },
                              pattern: {
                                value: REGEXP,
                                errorMessage:
                                  'Solo se permiten letras y un espacio entre nombres',
                              },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <Label>Apellidos</Label>
                          <AvField
                            type="text"
                            name="last_name"
                            className="form-control"
                            value={formValues.last_name}
                            onChange={handleInputChange}
                            validate={{
                              pattern: {
                                value: REGEXP,
                                errorMessage:
                                  'Solo se permiten letras y un espacio entre apellidos',
                              },
                            }}
                          />
                        </div>
                        <FormGroup className="mb-3">
                          <Label>Email</Label>
                          <AvField
                            type="email"
                            name="email"
                            className="form-control"
                            value={formValues.email}
                            onChange={handleInputChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'El email es obligatorio',
                              },
                              email: {
                                value: true,
                                errorMessage: 'Debe ser un email válido',
                              },
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label>Teléfono móvil</Label>
                          <AvField
                            type="text"
                            name="phone"
                            className="form-control"
                            value={formValues.phone}
                            onChange={handleInputChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: 'El teléfono es obligatorio',
                              },
                              pattern: {
                                value:
                                  '^\\+?(\\d{1,3})?[-.\\s]?(\\(?\\d{1,4}\\)?)?[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,9}$',
                                errorMessage: 'Número de teléfono no válido',
                              },
                              minLength: {
                                value: 9,
                                errorMessage: 'Debe tener al menos 9 dígitos',
                              },
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label>
                            Selecciona los partidos judiciales en los que deseas
                            colaborar
                          </Label>
                          <Select
                            placeholder="Selecciona el ámbito geográfico"
                            name="parties"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={judicialParties.map((i) => ({
                              value: i.id,
                              label: i.name,
                            }))}
                            isMulti
                            required
                            onChange={handlePartiesChange}
                            value={formValues.parties}
                          />
                        </FormGroup>
                        <div className="mb-4">
                          <Label>Contraseña</Label>
                          <InputGroup>
                            <div
                              className="text-bottom"
                              style={{ display: 'flex', width: '100%' }}
                            >
                              <AvField
                                name="password"
                                required
                                bsSize="lg"
                                type={!showPassword ? 'password' : 'text'}
                                validate={{
                                  pattern: {
                                    value:
                                      '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_]).{8,}$',
                                    errorMessage:
                                      'Mínimo 8 caracteres, 1 mayúscula, 1 minúscula, 1 número, 1 carácter especial',
                                  },
                                  minLength: { value: 8 },
                                }}
                                errorMessage="Mínimo 8 caracteres, 1 mayúscula, 1 minúscula, 1 número, 1 carácter especial"
                                style={{
                                  flex: 1,
                                  borderBottomRightRadius: '0px',
                                  borderTopRightRadius: '0px',
                                }}
                                value={formValues.password}
                                onChange={handleInputChange}
                              />
                              <PasswordEye
                                showPassword={!showPassword}
                                handleOnHover={() =>
                                  setShowPassword(!showPassword)
                                }
                                style={{
                                  padding: '0 10px',
                                  border: '1px solid #ced4da',
                                  borderLeft: 'none',
                                  borderRadius: '0 0.25rem 0.25rem 0',
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  width: '40px',
                                  justifyContent: 'center',
                                }}
                              />
                            </div>
                          </InputGroup>
                        </div>

                        <Button
                          disabled={!isFormValid || loading}
                          type="submit"
                          color="primary"
                          className="w-100"
                        >
                          Registrarse como{' '}
                          {formValues.is_procurador ? 'Procurador' : 'Abogado'}
                        </Button>
                      </AvForm>
                    )}
                    <div className="mt-4">
                      <small>
                        Ya tienes una cuenta en Legal Pigeon?{' '}
                        <Link to="/login">Inicia sesión</Link>
                      </small>
                    </div>
                  </CardBody>
                </Card>
              </>
            )}
          </Col>
          <Col
            md={6}
            className="d-none d-md-flex align-items-center justify-content-center p-0"
          >
            <img
              src={pigeonsLetters}
              alt="Legal Pigeon"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Col>
        </Row>
      </Container>
    </PageLayout>
  )
}

export default SubstituteSignUpPage
