import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PageLayout from '../layouts/Page'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from 'reactstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Loading from '../components/common/Loading'
import GoogleButton from 'react-google-button'
import { toast } from 'react-toastify'
import { googleSignUp } from '../services/socialLogin'
import { AWS_REDIRECT_URI_SIGNUP, ENABLE_SOCIAL_LOGIN } from '../config'
import pigeonsLetters from '../assets/images/37.png'
import { createClient, createClientIDP } from '../redux/actions/auth'
import { PasswordEye } from '../components/PasswordEye'
import { AvField, AvForm } from 'availity-reactstrap-validation'

// prettier-ignore
const REGEXP = '(^[A-Za-zÀ-ÖØ-öø-ÿ]+)(\\s{0,1})([A-Za-zÀ-ÖØ-öø-ÿ]+)$'

const ClientSignUpPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { activeUser, loading } = useSelector((state) => state.auth)

  const [searchParams, setSearchParams] = useState({})
  const [formValues, setFormValues] = useState({
    email: '',
    name: '',
    last_name: '',
    phone: '',
    password: '',
  })
  const [showPassword, setShowPassword] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )
  const sessionUtmParamsMemo = useMemo(
    () => sessionStorage.getItem('utmParams'),
    []
  )

  const updateUtmParams = useCallback(() => {
    if (
      query.get('utm_source') ||
      query.get('utm_campaign') ||
      query.get('utm_medium')
    ) {
      const utmParams = `utm_source=${query.get(
        'utm_source'
      )}&utm_medium=${query.get('utm_medium')}&utm_campaign=${query.get(
        'utm_campaign'
      )}`
      if (!sessionUtmParamsMemo) sessionStorage.setItem('utmParams', utmParams)
      setSearchParams({
        utm_source: query.get('utm_source') || '',
        utm_medium: query.get('utm_medium') || '',
        utm_campaign: query.get('utm_campaign') || '',
      })
    }
  }, [query, sessionUtmParamsMemo])

  const handleOAuth = useCallback(() => {
    if (query.get('state') && query.get('code') && !query.get('error')) {
      const code_verifier = sessionStorage.getItem(
        `code_verifier-${query.get('state')}`
      )
      sessionStorage.removeItem(`code_verifier-${query.get('state')}`)

      if (code_verifier === null) {
        console.error('Code verifier not found')
        return
      }
      dispatch(
        createClientIDP({
          code: query.get('code'),
          code_verifier,
          redirect_uri: AWS_REDIRECT_URI_SIGNUP,
          query: sessionUtmParamsMemo,
        })
      )
    }
  }, [query, sessionUtmParamsMemo, dispatch])

  useEffect(() => {
    updateUtmParams()
    handleOAuth()

    if (query.get('repeat') && !query.get('error')) {
      googleSignUp()
    }

    if (query.get('error')) {
      if (query.get('error_description')?.startsWith('Already found')) {
        navigate(`/client-signup?repeat=true`)
      } else {
        toast.error(
          `Ha ocurrido un error, contacte con el administrador, ${query.get(
            'error_description'
          )}`,
          {
            theme: 'colored',
          }
        )
        navigate(`/client-signup`)
      }
    }
  }, [
    location.search,
    query,
    sessionUtmParamsMemo,
    dispatch,
    navigate,
    updateUtmParams,
    handleOAuth,
  ])

  useEffect(() => {
    if (activeUser && activeUser._id) {
      navigate('/thanks-client')
    }
  }, [activeUser, navigate])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  useEffect(() => {
    const validateForm = () => {
      return (
        formValues.name.trim() &&
        formValues.last_name.trim() &&
        formValues.email.trim() &&
        formValues.phone.trim() &&
        formValues.password.trim() &&
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W|_]).{8,}$/.test(
          formValues.password
        )
      )
    }

    setIsFormValid(validateForm())
  }, [formValues])

  const handleSubmit = (e) => {
    e.preventDefault()

    const user = { ...formValues, role: 'client' }

    if (searchParams.utm_source) {
      user['utm_source'] = searchParams.utm_source
    }
    if (searchParams.utm_medium) {
      user['utm_medium'] = searchParams.utm_medium
    }
    if (searchParams.utm_campaign) {
      user['utm_campaign'] = searchParams.utm_campaign
    }

    dispatch(createClient({ user }))
  }

  const showGoogle = ENABLE_SOCIAL_LOGIN
  const handleInvalidSubmit = () => {
    setIsFormValid(false)
  }
  return (
    <PageLayout className="text-center">
      <Container fluid className="vh-100 d-flex align-items-center">
        <Row className="w-100 align-items-center">
          <Col
            md={6}
            className="d-flex flex-column align-items-center justify-content-center p-4"
          >
            {loading ? (
              <Loading loading={loading} />
            ) : (
              <>
                <div className="text-center mt-4 mb-4">
                  <h1 className="h2">
                    <span role="img" aria-label="rocket">
                      Empieza Hoy con Legal Pigeon 🚀
                    </span>
                  </h1>
                  <p className="lead">
                    Únete ahora y mejora la optimización de tus procesos legales
                    en minutos.
                  </p>
                </div>

                <Card className="w-100 shadow-sm" style={{ maxWidth: '500px' }}>
                  <CardBody>
                    {showGoogle && (
                      <>
                        <div className="d-flex justify-content-around mt-3 mb-4">
                          <GoogleButton
                            type="light"
                            onClick={googleSignUp}
                            label="Registrate con Google"
                            className="google-button"
                          />
                        </div>
                        <div className="text-center bold mb-4">
                          o rellena el formulario
                        </div>
                      </>
                    )}
                    <AvForm
                      onValidSubmit={handleSubmit}
                      onInvalidSubmit={handleInvalidSubmit}
                    >
                      <FormGroup className="mb-3">
                        <Label for="email">Email</Label>
                        <AvField
                          type="email"
                          name="email"
                          id="email"
                          bsSize="lg"
                          className="form-control"
                          value={formValues.email}
                          onChange={handleInputChange}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'El email es obligatorio',
                            },
                            email: {
                              value: true,
                              errorMessage: 'Debe ser un email válido',
                            },
                          }}
                        />
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Label for="name">Nombre</Label>
                        <AvField
                          type="text"
                          name="name"
                          id="name"
                          bsSize="lg"
                          className="form-control"
                          value={formValues.name}
                          onChange={handleInputChange}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'El nombre es obligatorio',
                            },
                            pattern: {
                              value: REGEXP,
                              errorMessage:
                                'Solo se permiten letras y un espacio entre nombres',
                            },
                          }}
                        />
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Label for="last_name">Apellidos</Label>
                        <AvField
                          type="text"
                          name="last_name"
                          id="last_name"
                          bsSize="lg"
                          className="form-control"
                          value={formValues.last_name}
                          onChange={handleInputChange}
                          validate={{
                            pattern: {
                              value: REGEXP,
                              errorMessage:
                                'Solo se permiten letras y un espacio entre apellidos',
                            },
                          }}
                        />
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Label for="phone">Teléfono</Label>
                        <AvField
                          type="text"
                          name="phone"
                          id="phone"
                          className="form-control"
                          value={formValues.phone}
                          bsSize="lg"
                          onChange={handleInputChange}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'El teléfono es obligatorio',
                            },
                            pattern: {
                              value:
                                '^\\+?[0-9]{1,3}(?:[-\\s]?[0-9]{2,4}){1,3}[0-9]{2,4}$',
                              errorMessage: 'Número de teléfono no válido',
                            },
                            minLength: {
                              value: 9,
                              errorMessage: 'Debe tener al menos 9 dígitos',
                            },
                          }}
                        />
                      </FormGroup>
                      <div className="mb-4">
                        <Label>Contraseña</Label>
                        <InputGroup>
                          <div
                            className="text-bottom"
                            style={{ display: 'flex', width: '100%' }}
                          >
                            <AvField
                              name="password"
                              required
                              bsSize="lg"
                              type={!showPassword ? 'password' : 'text'}
                              validate={{
                                pattern: {
                                  value:
                                    '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_]).{8,}$',
                                  errorMessage:
                                    'Mínimo 8 caracteres, 1 mayúscula, 1 minúscula, 1 número, 1 carácter especial',
                                },
                                minLength: { value: 8 },
                              }}
                              errorMessage="Mínimo 8 caracteres, 1 mayúscula, 1 minúscula, 1 número, 1 carácter especial"
                              style={{
                                flex: 1,
                                borderBottomRightRadius: '0px',
                                borderTopRightRadius: '0px',
                              }}
                              value={formValues.password}
                              onChange={handleInputChange}
                            />
                            <PasswordEye
                              showPassword={!showPassword}
                              handleOnHover={() =>
                                setShowPassword(!showPassword)
                              }
                              style={{
                                padding: '0 10px',
                                border: '1px solid #ced4da',
                                borderLeft: 'none',
                                borderRadius: '0 0.25rem 0.25rem 0',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                width: '40px',
                                justifyContent: 'center',
                              }}
                            />
                          </div>
                        </InputGroup>
                      </div>

                      <Button
                        disabled={!isFormValid || loading}
                        type="submit"
                        color="primary"
                        className="w-100"
                      >
                        Registrarse
                      </Button>
                    </AvForm>
                    <div className="mt-4">
                      <small>
                        Ya tienes una cuenta en Legal Pigeon?{' '}
                        <Link to="/login">Inicia sesión</Link>
                      </small>
                    </div>
                  </CardBody>
                </Card>
              </>
            )}
          </Col>
          <Col
            md={6}
            className="d-none d-md-flex align-items-center justify-content-center p-0"
          >
            <img
              src={pigeonsLetters}
              alt="Legal Pigeon"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Col>
        </Row>
      </Container>
    </PageLayout>
  )
}

export default ClientSignUpPage
